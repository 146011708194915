@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 255, 255, 255;
  --background-end-rgb: 255, 255, 255;
  --swiper-pagination-bullet-inactive-color: white;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 8px;
  --swiper-pagination-bottom: 15px;
  --swiper-pagination-bullet-size: 10px;
  --swiper-navigation-top-offset: 100%;
  --swiper-navigation-size: 40px;

}

@media (prefers-color-scheme: dark) {
  :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 255, 255, 255;
  --background-end-rgb: 255, 255, 255;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

.swiper-free-mode > .swiper-wrapper{
  transition-timing-function : linear !important;
}
